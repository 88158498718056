<template>
  <div class="main">
    <Title></Title>
    <div class="bgc"></div>
    <div class="content c">
      <div class="contentLeft">
        <div class="PCenter">个人中心</div>
        <!-- leftMenu[0].router -->
        <el-menu :default-active="routerInfo" @select="selectLeftMenu">
          <el-menu-item
            v-for="(item, index) in leftMenu"
            :key="index"
            :index="item.router"
          >
            <!-- <el-menu :default-active="0" @select="selectLeftMenu">
          <el-menu-item
            v-for="(item, index) in leftMenu"
            :key="index"
            :index="item"
          > -->
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="contentRight">
        <!-- <router-view><router-view> -->
        <router-view ref="rigthview"></router-view>
      </div>
    </div>
    <Footer style="margin-top: 100px"></Footer>
  </div>
</template>

<script>
// import FuYong from '../../components/fuyong/fuyongtitle.vue'
import { getJobUserAuth } from '@/api/jobInformation/jobInformation'
import { getLoginUser } from '@/api/login/login'
import Title from '@/components/hometitle/hometitle.vue'
import Footer from '@/components/foot/foot.vue'
import { detailTeam } from "@/api/team/team";
export default {
  name: '',
  components: {
    Title,
    Footer,
  },
  data() {
    return {
      headerCList: ['首页', '职位搜索', '求职信息', '在线学习', '关于我们'],
      headerRightList: [
        {
          top: require('@/assets/personCenter/appT.png'),
          bottom: require('@/assets/personCenter/appB.png'),
        },
        {
          top: require('@/assets/personCenter/headerRight2T.png'),
          bottom: require('@/assets/personCenter/headerRight2B.png'),
        },
        {
          top: require('@/assets/personCenter/headerRight3T.png'),
          bottom: require('@/assets/personCenter/headerRight3B.png'),
        },
      ],
      routerInfo: 'resumeManagement',
      // leftMenu: ['简历管理','简历投递','专场招聘','我的订单','加入班组','信用评价'
      leftMenu: [
        {
          name: '简历管理',
          router: 'resumeManagement',
        },
         {
          name: '信用评价',
          router: 'creditrating',
        },
        {
          name: '简历投递',
          router: 'resumeDelivery',
        },
        {
          name: '我的收藏',
          router: 'myCollection',
        },
        {
          name: '面试管理',
          router: 'interviewManagement',
        },
        {
          name: '专场招聘',
          router: 'specialRecruitment',
        },
        {
          name: '我的订单',
          router: 'myOrder',
        },
        {
          name:'我的考试',
          router:'exam',
        },
        {
          name:'我的课程',
          router:'course',
        },
        {
          name:'我的培训',
          router:'training',
        },
        {
          name: '求职信息',
          router: 'myJobInformation'
        },
        {
          name: '营销服务',
          router: 'marketingCenter'
        },
         {
          name: '权益中心',
          router: 'memberCenter'
        },
        // {
        //   name: '加入班组',
        //   router: '',
        // },
        {
          name: '班组信息',
          router: 'teamInfo',
        },
        {
          name: '个人设置',
          router: 'personalSettings',
        },
        {
          name:'消息中心',
          router:'message',
        },
        {
          name:'消费记录',
          router:'consumption',
        },
      ],
      userTeam: {}
    }
  },
  watch: {},
  props: {},
  // components: {},
  computed: {},
  created() {
    let path = window.location.href
    this.routerInfo = path.substring(path.lastIndexOf('/') + 1,path.length)
    this.getUser()
    console.log(this.$route.query,'个人中心')
    if(this.$route.query.idCard){
      this.routerInfo = 'creditrating'
    }
    if(this.$route.query.path){
      this.routerInfo = this.$route.query.path
    }
  },
  mounted() {},
  methods: {
    selectLeftMenu(e) {
      console.log(e)
      if (e === 'creditrating') {
          setTimeout(() => {
          if(this.userAuth && this.userAuth.audit_status == '2'){
            this.$refs.rigthview.loadData(this.userAuth.identity_code,1)
          }else if(this.userTeam && this.userTeam.auditStatus == 2){
            this.$refs.rigthview.loadData(this.userTeam.idCard,2)
          }else{
            this.$refs.rigthview.loadData('',5)
            this.$message.error("个人/班组认证审核成功后才可查看信用详细信息");
          }
        }, 1000);
      }

      if (e != this.$route.path) {
        this.$router.push(e)
      }
    },
    getUser() {
      getLoginUser().then(res => {
        console.log(res, '获取用户')
        localStorage.setItem('userId', res.data.id)
      })
      let jobUserId = localStorage.getItem("userId");
        detailTeam({ jobUserId }).then((res) => {
        this.userTeam = res.data
      })
      getJobUserAuth().then(res => {
        console.log(res.data)
        this.userAuth = res.data
       // this.form.identityCode = this.certificates.identity_code

      })
    },
  },
  filters: {},
}
</script>
<style scoped lang="scss">
.c {
  // width: 1570px;
  width: 1226px;
  margin: 0 auto;
}
.bgc {
  margin-top: 80px;
  // width: 100%;
  height: 350px;
  background: url('~@/assets/personCenter/bgc.png');
  background-size: 100% auto;
}
.header {
  width: 100%;
  background-color: #029aff;
  .top {
    height: 68px;
    // background-color: #029aff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 0 20px;
    .left {
      display: flex;
      align-content: center;
      img {
        width: 36px;
        height: 36px;
        margin-right: 20px;
      }
      span {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 18px;
      }
    }
    .center {
      display: flex;
      div {
        margin: 0 20px;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .imgBox {
        position: relative;
        margin-top: 8px;
        img:nth-child(1) {
          width: 38px;
          height: 18px;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
        }
        img:nth-child(2) {
          width: 56px;
          height: 56px;
        }
      }
      .personImg {
        width: 36px;
        height: 36px;
        margin-left: 20px;
      }
    }
  }
  .bottom {
    height: 160px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .lunboText {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #003a5c8c;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #fff;
    }
  }
}

.content {
  margin-top: -110px;
  display: flex;
  justify-content: space-between;
  .contentLeft {
    width: 220px;
    background-color: #fff;
    min-height: 900px;
    padding-bottom: 20px;
    .PCenter {
      height: 64px;
      line-height: 64px;
      color: #4ea3ff;
      text-align: center;
      font-size: 24px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .el-menu {
      .is-active {
        color: #4ea3ff !important;
        background-color: #fff;
        position: relative;
      }
      .is-active::after {
        content: '';
        width: 100%;
        height: 2px;
        display: block;
        background: url('~@/assets/personCenter/line.png') no-repeat;
        background-size: 90% 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-position: center;
      }
      .el-menu-item {
        font-size: 18px;
        text-align: center;
        color: #666;
      }
    }
  }
  .contentRight {
    width: 986px;
  }
}

// .el-carousel {
//   ::v-deep .el-carousel__indicators--horizontal {
//     right: 20px;
//     transform: none;
//   }
// }
</style>
